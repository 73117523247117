import React, { useState, useEffect, useCallback } from 'react';
import './Filters.css';
import { FaArrowLeft, FaSearch, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import * as digiKeyService from '../../services/digiKeyService';

const Filters = ({ 
  categoryData, 
  selectedFilters, 
  onFilterChange, 
  onApplyFilters, 
  onClearAll, 
  onDeepSearch,
  onBack,
  isLoadingLocal,
  setRecommendations,
  categoryName,
  setIsLoadingLong,
  currentPage,
  setCurrentPage,
  recommendations // Add recommendations prop to access current products
}) => {
  const [searchTerms, setSearchTerms] = useState({});
  const [showSearch, setShowSearch] = useState({});
  const [showRequirementsModal, setShowRequirementsModal] = useState(false);
  const [showQuickFilterModal, setShowQuickFilterModal] = useState(false);
  const [requirements, setRequirements] = useState('');
  const [quickFilterInput, setQuickFilterInput] = useState('');
  const [pendingFilters, setPendingFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [specifications, setSpecifications] = useState([]);
  const [partsLimit, setPartsLimit] = useState(50);
  const [lastAppliedFilters, setLastAppliedFilters] = useState(null);
  const [currentProductDetails, setCurrentProductDetails] = useState([]);

  // Check if there are any pending changes
  const hasPendingChanges = Object.keys(pendingFilters).length > 0;

  useEffect(() => {
    // Initialize applied filters with current selections
    setAppliedFilters(selectedFilters);
    // Also clear pending filters when selected filters change
    setPendingFilters({});
  }, [selectedFilters]);

  // Store a reference to the current recommendations
  useEffect(() => {
    if (recommendations && recommendations.length > 0) {
      // Store the current recommendations for use in the deep search
      setCurrentProductDetails(recommendations);
    }
  }, [recommendations]);

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        setShowRequirementsModal(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const handleModalOverlayClick = (e) => {
    if (e.target.className === 'requirements-modal-overlay') {
      setShowRequirementsModal(false);
    }
  };

  const handleBack = () => {
    setRecommendations([]);
    onBack();
    setSearchTerms({});
  };

  const handleFilterClick = (parameterId, filterId) => {
    // Check if the filter is already selected
    const isSelected = (selectedFilters[parameterId] || []).includes(filterId);
    
    // Get current pending selections
    const currentPendingSelections = pendingFilters[parameterId] || [];
    let newPendingSelections;
    
    if (isSelected) {
      // If it's selected, toggle its removal status in pending
      if (currentPendingSelections.includes(filterId)) {
        // If it's in pending for removal, cancel the removal
        newPendingSelections = currentPendingSelections.filter(id => id !== filterId);
      } else {
        // If it's not in pending, mark it for removal
        newPendingSelections = [...currentPendingSelections, filterId];
      }
    } else {
      // If it's not selected, toggle it in pending for addition
      if (currentPendingSelections.includes(filterId)) {
        newPendingSelections = currentPendingSelections.filter(id => id !== filterId);
      } else {
        newPendingSelections = [...currentPendingSelections, filterId];
      }
    }

    // Update pending filters if there are changes
    if (newPendingSelections?.length > 0) {
      setPendingFilters(prev => ({
        ...prev,
        [parameterId]: newPendingSelections
      }));
    } else {
      // Remove the parameter from pending if no selections
      setPendingFilters(prev => {
        const { [parameterId]: removed, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleApplyAll = () => {
    // Collect all filter changes first
    const newFilters = { ...selectedFilters }; // Start with existing selected filters
    
    Object.entries(pendingFilters).forEach(([parameterId, values]) => {
      // Get current selections from parent
      const currentSelections = selectedFilters[parameterId] || [];
      
      // For each value in pending, toggle its presence in the selections
      const newSelections = currentSelections.filter(id => !values.includes(id))
        .concat(values.filter(id => !currentSelections.includes(id)));
      
      // Store the new selections
      newFilters[parameterId] = newSelections;
    });

    // Apply all filter changes at once
    Object.entries(newFilters).forEach(([parameterId, values]) => {
      onFilterChange(parameterId, values);
    });
    
    // Clear pending filters after applying
    setPendingFilters({});
    
    // Call onApplyFilters with the new filters
    onApplyFilters(newFilters);
    
    // Store the applied filters to compare with lastAppliedFilters
    setLastAppliedFilters(JSON.stringify(newFilters));
  };

  const toggleSearch = (parameterId) => {
    setShowSearch(prev => ({
      ...prev,
      [parameterId]: !prev[parameterId]
    }));
  };

  const handleSearchChange = (parameterId, searchTerm) => {
    setSearchTerms(prev => ({
      ...prev,
      [parameterId]: searchTerm
    }));

    // Automatically filter as user types
    const matchingValues = filterValues(
      categoryData.filters.find(f => f.ParameterId === parameterId).FilterValues,
      parameterId
    );
  };

  const handleSearchClick = (parameterId) => {
    const searchTerm = searchTerms[parameterId] || '';
    if (!searchTerm) return;

    const matchingValues = filterValues(categoryData.filters.find(f => f.ParameterId === parameterId).FilterValues, parameterId);
    if (matchingValues.length > 0) {
      const valueIds = matchingValues.map(v => v.ValueId);
      onFilterChange(parameterId, valueIds);
    }
  };

  const filterValues = (values, parameterId) => {
    const searchTerm = searchTerms[parameterId] || '';
    if (!searchTerm) return values;
    
    // Split search terms by spaces, remove empty strings, and clean terms
    const searchTermArray = searchTerm.toLowerCase()
      .split(/\s+/)
      .filter(term => term.length > 0)
      .map(term => term.replace(/[-_]/g, ''));  // Remove dashes and underscores

    if (searchTermArray.length === 0) return values;
    
    // Filter and score the values
    const scoredValues = values.map(value => {
      // Clean the value name for comparison
      const cleanedValueName = value.ValueName.toLowerCase().replace(/[-_]/g, '');
      
      // Initialize score
      let score = 0;
      let allTermsMatch = true;
      
      // Check each search term
      for (const term of searchTermArray) {
        let termMatched = false;
        const variations = [
          term,                              // Original term
          term.replace(/s$/, ''),            // Remove trailing 's'
          term.replace(/es$/, ''),           // Remove trailing 'es'
          term.replace(/ies$/, 'y'),         // Handle 'ies' -> 'y'
          term.replace(/(\d+)([a-z]+)/, '$1 $2'),  // Split numbers and letters
        ];

        for (const variant of variations) {
          // Exact match of the whole value
          if (cleanedValueName === variant) {
            score += 1000;
            termMatched = true;
            break;
          }
          
          // Match at start of value
          if (cleanedValueName.startsWith(variant)) {
            score += 500;
            termMatched = true;
            break;
          }
          
          // Match at start of any word in the value
          if (cleanedValueName.split(/\s+/).some(word => word.startsWith(variant))) {
            score += 200;
            termMatched = true;
            break;
          }
          
          // Contains the term anywhere
          if (cleanedValueName.includes(variant)) {
            score += 100;
            termMatched = true;
            break;
          }
        }
        
        if (!termMatched) {
          allTermsMatch = false;
          break;
        }
      }

      return {
        value,
        score: allTermsMatch ? score : -1
      };
    });

    // Filter out non-matches and sort by score
    return scoredValues
      .filter(item => item.score > 0)
      .sort((a, b) => b.score - a.score)
      .map(item => item.value);
  };

  const handleQuickFilter = async () => {
    try {
      // Set loading state and hide modal immediately
      setIsLoadingLong(true);
      setShowQuickFilterModal(false);

      // We don't need to clear existing filters unless we're explicitly replacing them
      // Instead, we'll merge new filters with existing ones
      
      // Join all specifications with double commas to create the complete input
      const completeInput = specifications.join(',,');
      
      const response = await digiKeyService.getFilteredProducts({
        user_input: completeInput,
        category_id: categoryData.category_id,
        category_name: categoryName
      });
      
      // Create a new filters object that starts with the existing filters
      const newFilters = { ...selectedFilters };
      
      // The v3 endpoint returns an array of objects with parameter_id and value_ids directly
      // No need to access response.data anymore since axios returns the data property
      if (Array.isArray(response)) {
        response.forEach(filter => {
          if (filter.parameter_id && Array.isArray(filter.value_ids)) {
            // Make sure parameter_id is a string for consistency
            const parameterId = filter.parameter_id.toString();
            // value_ids should already be strings from the API
            const valueIds = filter.value_ids;
            
            // Update the filters
            newFilters[parameterId] = valueIds;
            // Update parent's filters one by one
            onFilterChange(parameterId, valueIds);
          }
        });
      }

      // Set the applied filters in local state
      setAppliedFilters(newFilters);
      
      // Apply the filters to update the display
      onApplyFilters();
      
      // Save the input for Scan Parts if needed
      setRequirements(completeInput);
      
      // Reset states
      setQuickFilterInput('');
      setSpecifications([]);
      
    } catch (error) {
      console.error("Error applying quick filters:", error);
      // Show the modal again if there's an error
      setShowQuickFilterModal(true);
    } finally {
      setIsLoadingLong(false);
    }
  };

  const handleAddSpecification = () => {
    if (quickFilterInput.trim()) {
      setSpecifications(prev => [...prev, quickFilterInput.trim()]);
      setQuickFilterInput('');
    }
  };

  const handleDeleteSpecification = (index) => {
    setSpecifications(prev => prev.filter((_, i) => i !== index));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddSpecification();
    }
  };

  const handleClearAll = () => {
    // Clear both pending and applied filters
    setPendingFilters({});
    setAppliedFilters({});
    // Call parent's clear all
    onClearAll();
  };

  const handleScanDatasheets = () => {
    setShowRequirementsModal(true);
  };

  const handleRequirementsSubmit = () => {
    // Close the modal
    setShowRequirementsModal(false);
    
    // Set loading state to indicate processing
    setIsLoadingLong(true);
    
    // Hide filters and navigate to the jobs page
    // Instead of calling onBack(), set current page to "jobs"
    setCurrentPage("jobs");
    
    // Get the current recommendations directly
    try {
      // Instead of stringifying here, pass the raw array directly
      // The handleDeepSearch function will handle the stringification
      if (recommendations && Array.isArray(recommendations) && recommendations.length > 0) {
        console.log("Using raw recommendations array with", recommendations.length, "products");
        
        // Call deep search with the raw recommendations array
        onDeepSearch(categoryName, requirements, recommendations);
      } else {
        console.warn("No recommendations available to use in deep search");
        // Call deep search without product details
        onDeepSearch(categoryName, requirements);
      }
    } catch (error) {
      console.error("Error preparing product details for deep search:", error);
      // Fall back to just using requirements without product details
      onDeepSearch(categoryName, requirements);
    }
  };

  return (
    <div className="filters-container">
      <div className="filters-header">
        <div className="filters-header-left" style={{ display: 'flex', alignItems: 'center' }}>
          <button className="back-button" onClick={handleBack}>
            <FaArrowLeft /> Back
          </button>
          <h2 style={{ margin: '0 0 0 10px' }}>
            {categoryName.split('>>').map((part, i, arr) => (
              <React.Fragment key={i}>
                {part.trim()}
                {i < arr.length - 1 && <span className="category-separator">&gt;&gt;</span>}
              </React.Fragment>
            ))}
          </h2>
        </div>
        <div className="filters-header-actions" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {/* <button 
            className="deep-search-button"
            onClick={handleScanDatasheets}
          >
            Scan Datasheets
          </button>
          <div style={{
            width: '1px',
            height: '26px',
            backgroundColor: '#ccc',
            margin: '0 10px',
            alignSelf: 'center'
          }} /> */}
          <button 
            onClick={handleApplyAll}
            style={{
              backgroundColor: '#fff',
              color: '#4d8df5',
              border: '2px solid #4d8df5',
              cursor: 'pointer',
              minHeight: '32px',
              whiteSpace: 'nowrap',
              padding: '6px 12px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Apply All
          </button>
          <button 
            onClick={handleClearAll}
            style={{
              backgroundColor: '#fff',
              color: '#666',
              border: '1px solid #ccc',
              cursor: 'pointer',
              minHeight: '32px',
              whiteSpace: 'nowrap',
              padding: '6px 12px',
              display: 'flex',
              alignItems: 'center'
            }}
          >Clear All</button>
        </div>
      </div>

      <div className="filters-scroll-container">
        <div className="filters-row">
          {categoryData.filters.map((filter) => (
            <div 
              key={filter.ParameterId} 
              className={`filter-group ${showSearch[filter.ParameterId] ? 'search-active' : ''}`}
            >
              <div className="filter-header">
                <h3>{filter.ParameterName}</h3>
                {((selectedFilters[filter.ParameterId]?.length || 0) > 0) && (
                  <span className="selected-count">
                    {selectedFilters[filter.ParameterId]?.length || 0} selected
                  </span>
                )}
                <button 
                  className="filter-search-toggle"
                  onClick={() => toggleSearch(filter.ParameterId)}
                >
                  <FaSearch />
                </button>
              </div>
              {showSearch[filter.ParameterId] && (
                <div className="search-input-container">
                  <FaSearch className="search-icon" />
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerms[filter.ParameterId] || ''}
                    onChange={(e) => handleSearchChange(filter.ParameterId, e.target.value)}
                    className="filter-search-input"
                  />
                </div>
              )}
              <div className="filter-options">
                {filterValues(filter.FilterValues, filter.ParameterId).map((value) => {
                  const isPending = (pendingFilters[filter.ParameterId] || []).includes(value.ValueId);
                  const isSelected = (selectedFilters[filter.ParameterId] || []).includes(value.ValueId);
                  
                  // If it's selected and pending, it means it's marked for removal
                  const isActive = (isSelected && !isPending) || (!isSelected && isPending);
                  
                  return (
                    <div
                      key={value.ValueId}
                      className={`filter-option ${isActive ? 'selected' : ''}`}
                      onClick={() => handleFilterClick(filter.ParameterId, value.ValueId)}
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%'
                      }}
                      title={value.ValueName}
                    >
                      {value.ValueName}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>

      {showRequirementsModal && (
        <div className="requirements-modal-overlay" onClick={handleModalOverlayClick}>
          <div className="requirements-modal">
            <h2>Scan Datasheets</h2>
            <p>What information would you like to see about these components?</p>
            <textarea
              value={requirements}
              onChange={(e) => setRequirements(e.target.value)}
              style={{ marginBottom: '16px' }}
              placeholder="Show me thermal specs and power ratings"
            />
            <div className="modal-buttons">
              <button onClick={handleRequirementsSubmit} className="primary-button">Submit</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;