import React, { useState, useEffect } from "react";
import ProductForm from "./components/ProductForm/ProductForm";
import ProductList from "./components/ProductList/ProductList";
import Header from "./components/Header";
import ExampleSearches from "./components/ExampleSearches";
import Spinner from "./components/Spinner/Spinner";
import Jobs from "./Jobs";
import SearchForm from "./components/SearchForm/SearchForm";
import { v4 as uuidv4 } from "uuid";
import ReactGA from 'react-ga4';

// Function to generate a new session ID
function App() {
  const [recommendations, setRecommendations] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLong, setIsLoadingLong] = useState(false);
  const [initialQueryDone, setInitialQueryDone] = useState(false);
  const [storedInput, setStoredInput] = useState("");
  const [description, setDescription] = useState(""); // New state for description
  const [currentPage, setCurrentPage] = useState("search");
  const [jobs, setJobs] = useState({});
  const [isLoadingNoText, setIsLoadingNoText] = useState(false);

  // Function to generate a new session ID
  const generateNewSessionId = () => {
    const newSessionId = uuidv4();
    const timestamp = Date.now();
    localStorage.setItem("sessionId", newSessionId);
    localStorage.setItem("sessionIdTimestamp", timestamp);
    setSessionId(newSessionId);
    
    // No need to track session-related events
    return newSessionId;
  };

  useEffect(() => {
    // Initialize Google Analytics for GA4
    ReactGA.initialize('G-V8KZT5PHMM');
    
    // Track page view in GA4 - this is all we need for user presence tracking
    ReactGA.send({ 
      hitType: "pageview", 
      page: window.location.pathname + window.location.search 
    });

    // Check if session ID exists in localStorage and is not older than a day
    const storedSessionId = localStorage.getItem("sessionId");
    const storedTimestamp = localStorage.getItem("sessionIdTimestamp");
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    if (storedSessionId && storedTimestamp && (Date.now() - storedTimestamp < oneDayInMilliseconds)) {
      setSessionId(storedSessionId);
      
      // Set user ID for session tracking but no event
      ReactGA.set({ user_id: storedSessionId });
    } else {
      const newId = generateNewSessionId();
      
      // Set user ID for new sessions
      ReactGA.set({ user_id: newId });
    }
  }, []);

  const handleNewSearch = () => {
    // Reset the state for a new search
    setRecommendations([]);
    setSearchCompleted(false);
    setInitialQueryDone(false);

    // Generate a new session ID for the new search
    generateNewSessionId();
    
    // No event tracking needed
  };

  const handleSearchComplete = () => {
    console.log("Search complete, storing user input:", input);
    
    // Mark the search as completed
    setSearchCompleted(true);
    
    // Store the input before clearing it
    if (input && input.trim()) {
      console.log("Setting storedInput to:", input.trim());
      setStoredInput(input.trim());
      
      // Also store in localStorage as backup
      localStorage.setItem('originalUserQuery', input.trim());
    } else {
      console.warn("Empty input received in handleSearchComplete!");
    }
    
    setInitialQueryDone(true);
  };

  return (
    <div className="App" style={styles.app}>
      <Header 
        handleNewSearch={handleNewSearch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sessionId={sessionId}
        setRecommendations={setRecommendations}
      />
      <Spinner isLoading={isLoading} isLoadingLong={isLoadingLong} isLoadingNoText={isLoadingNoText} />
      <div style={styles.content}>
        {currentPage === "search" && (
          <>
            <ProductForm
              setRecommendations={setRecommendations}
              sessionId={sessionId}
              onSearchComplete={handleSearchComplete}
              input={input}
              setInput={setInput}
              setIsLoading={setIsLoading}
              handleNewSearch={handleNewSearch}
              setStoredInput={setStoredInput} // Pass setStoredInput to ProductForm
              description={description}
              setIsLoadingLong={setIsLoadingLong}
              setIsLoadingNoText={setIsLoadingNoText}
              setJobs={setJobs}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              parentRecommendations={recommendations} // Pass the recommendations directly to ProductForm
            />
            {recommendations.length > 0 && (
              <ProductList 
                recommendations={recommendations} 
                setRecommendations={setRecommendations}
                setIsLoadingLong={setIsLoadingLong}
                sessionId={sessionId} 
                userQuery={storedInput} 
                setDescription={setDescription} // Pass setDescription to ProductList
                isLoadingLong={isLoadingLong}
              />
            )}
          </>
        )}
        {currentPage === "jobs" && (
          <Jobs jobs={jobs} setJobs={setJobs} sessionId={sessionId} setIsLoadingLong={setIsLoadingLong} />
        )}
      </div>
    </div>
  );
}

const styles = {
  app: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    backgroundColor: "#f4f4f9",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", // Center contents vertically
    boxSizing: "border-box",
  },
  header: {
    marginLeft: "20px",
    color: "#333",
  },
  newSearchButton: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  content: {
    marginTop: "80px", // Ensure content is not hidden under the header
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  description: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "80%",
    textAlign: "center",
  },
  emptyState: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "80%",
    textAlign: "center",
    color: "#999",
    fontSize: "1rem",
  }
};

export default App;
