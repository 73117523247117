import React from "react";
import { FaBars, FaBell, FaRegBell, FaTimes } from "react-icons/fa";
import "./Header.css";

const Header = ({ handleNewSearch, currentPage, setCurrentPage, sessionId, setRecommendations }) => {
  const handleFeedbackClick = () => {
    alert("Component Finder is still in beta testing. For questions or feedback, please email chandler.supple@gmail.com");
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="left-section">
          <h1 className="logo">Component Finder - Beta</h1>
        </div>
        <div className="right-section">
          {/* <span 
            className={`page-link ${currentPage === "search" ? "active" : ""}`}
            onClick={() => {
              setCurrentPage("search");
              setRecommendations([]); // Clear recommendations when switching to search
            }}
          >
            Search
          </span>
          <span 
            className={`page-link ${currentPage === "jobs" ? "active" : ""}`}
            onClick={() => setCurrentPage("jobs")}
          >
            Results
          </span> */}
          
          <a 
            href="https://docs.google.com/document/d/1w9_B2uhmP96WuakioGxeSI5NwPlhLrCp1bZ-u_Qih-A/edit?usp=sharing" 
            target="_blank" 
            rel="noopener noreferrer"
            className="alpha-signup-link"
          >
            Sign Up for Alpha Release
          </a>
          
          <button 
            className="feedback-button"
            onClick={handleFeedbackClick}
          >
            Feedback
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
