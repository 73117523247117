import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaSearch, FaTimes, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import debounce from "lodash.debounce";
import ReactMarkdown from "react-markdown";
// Keep ReactGA import for consistency, but we won't use it for events
import ReactGA from 'react-ga4';
import "./ProductForm.css";
import Filters from '../Filters/Filters';
import ProductList from '../ProductList/ProductList';
// Import the DigiKey service 
import * as digiKeyService from '../../services/digiKeyService';

const WAIT_TIME_MS = 1000; // Constant for wait time

// Digi-Key API Credentials
// Moved to digiKeyService.js

// Common product mapping function to ensure consistency
const mapDigiKeyProduct = (p) => ({
  id: crypto.randomUUID(),
  title: p.Description.ProductDescription,
  price: `$${p.UnitPrice}`,
  image: p.PhotoUrl,
  vendor: "digikey.com",
  description: p.Description.DetailedDescription,
  link: p.ProductUrl,
  datasheet: p.DatasheetUrl,          // Keep existing datasheet field
  datasheet_link: p.DatasheetUrl,     // Add new datasheet_link field
  manufacturer: p.Manufacturer.Name,
  manufacturerPartNumber: p.ManufacturerProductNumber,
  detailedDescription: p.Description.DetailedDescription,
  stockStatus: p.QuantityAvailable > 0 ? 'In Stock' : 'Out of Stock',
  quantityAvailable: p.QuantityAvailable
});

// Parse categories data into a more usable format
const parseCategories = (data) => {
  return data.split('\n')
    .filter(line => line.trim())
    .map(line => {
      const [path, id] = line.split(': ');
      return {
        path,
        id: parseInt(id),
        searchText: path.toLowerCase()
      };
    });
};

// Initialize categories as empty array
let categories = [];

// Fetch categories data
fetch('/categories.txt')
  .then(response => response.text())
  .then(data => {
    categories = parseCategories(data);
  })
  .catch(error => console.error('Error loading categories:', error));

// Function to search categories
const searchCategories = (query) => {
  const searchTerms = query.toLowerCase().split(' ').filter(term => term.length > 0);
  
  // Return empty array if no valid search terms
  if (searchTerms.length === 0) return [];

  // Score and filter categories
  const scoredResults = categories
    .map(cat => {
      const pathLower = cat.path.toLowerCase();
      let score = 0;
      let matchedTerms = 0;
      let earliestPosition = Infinity;

      // Check each search term
      searchTerms.forEach(term => {
        const position = pathLower.indexOf(term);
        if (position !== -1) {
          matchedTerms++;
          // Update earliest position if this term appears earlier
          earliestPosition = Math.min(earliestPosition, position);
          
          // Give higher score for matches at the start of words
          if (pathLower.includes(' ' + term) || pathLower.startsWith(term)) {
            score += 2;
          } else {
            score += 1;
          }
        }
      });

      return {
        label: cat.path,
        id: cat.id,
        score: score,
        matchedTerms: matchedTerms,
        earliestPosition: earliestPosition === Infinity ? -1 : earliestPosition
      };
    })
    // Filter out items that don't match any terms
    .filter(result => result.matchedTerms > 0)
    // Sort by number of matched terms first, then by earliest position, then by score
    .sort((a, b) => {
      if (b.matchedTerms !== a.matchedTerms) {
        return b.matchedTerms - a.matchedTerms;
      }
      if (a.earliestPosition !== b.earliestPosition) {
        return a.earliestPosition - b.earliestPosition;
      }
      return b.score - a.score;
    })
    .slice(0, 10); // Limit to 10 results

  return scoredResults;
};

const ProductForm = ({ setRecommendations, recommendations, sessionId, onSearchComplete, input, setInput, setIsLoading, setIsLoadingLong, storedInput, setStoredInput, description, setJobs, setIsLoadingNoText, currentPage, setCurrentPage, parentRecommendations }) => {
  const [specificSite, setSpecificSite] = useState("digikey.com");
  const [numLinks, setNumLinks] = useState(6);
  const [numRecommendations, setNumRecommendations] = useState(6);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [searchInput, setSearchInput] = useState(input);
  const [suggestions, setSuggestions] = useState([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [response, setResponse] = useState("");
  const [isEscPressed, setIsEscPressed] = useState(false);
  const [searchMade, setSearchMade] = useState(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);
  const [localRecommendations, setLocalRecommendations] = useState([]);
  const [localDescription, setLocalDescription] = useState(description);
  const [isLoadingLongLocal, setIsLoadingLongLocal] = useState(false);
  const fetchSuggestionsRef = useRef(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [searchDuration, setSearchDuration] = useState(0);
  const [activeTab, setActiveTab] = useState("newSearch"); // New state for active tab
  const [similarProductLink, setSimilarProductLink] = useState(""); // New state for similar product link
  const [questionInput, setQuestionInput] = useState(""); // New state for question input
  const [lastSearchInput, setLastSearchInput] = useState(""); // New state for storing the last search input
  const [searchMadeInTabs, setSearchMadeInTabs] = useState({
    newSearch: false,
    similarProduct: false,
    askQuestion: false,
    guidedSearch: false,
  });
  const [guidedQuestions, setGuidedQuestions] = useState([]);
  const [guidedAnswers, setGuidedAnswers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeepSearchInitiated, setIsDeepSearchInitiated] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [partsLimit, setPartsLimit] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [categoryProducts, setCategoryProducts] = useState({});
  const [debouncedSearchInput, setDebouncedSearchInput] = useState('');
  
  // New states for multi-step modal workflow
  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
  const [workflowStep, setWorkflowStep] = useState(1); // 1: Category, 2: Filters, 3: Query
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [finalQuery, setFinalQuery] = useState('');
  const [initialComponentDescription, setInitialComponentDescription] = useState('');
  const [modalSelectedFilters, setModalSelectedFilters] = useState({});
  
  // Create a local isLoading state that syncs with the prop
  const [isLoading, _setIsLoading] = useState(false);
  const setLocalIsLoading = (value) => {
    _setIsLoading(value);
    setIsLoading(value);
  };

  // Separate loading states for different parts of the workflow
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  // Filter search state
  const [filterSearchTerms, setFilterSearchTerms] = useState({});
  const [showFilterSearch, setShowFilterSearch] = useState({});

  // Toggle filter search visibility
  const toggleFilterSearch = (parameterId) => {
    console.log('Toggling search for parameter:', parameterId, 'Current state:', showFilterSearch[parameterId]);
    
    // Create a fresh copy of the state
    const newState = { ...showFilterSearch };
    
    // Toggle the state for this parameter
    newState[parameterId] = !newState[parameterId];
    
    // Log the action in detail
    console.log(`Setting search visibility for parameter ${parameterId} to ${newState[parameterId]}`);
    
    // Update state
    setShowFilterSearch(newState);
    
    // Add a slight delay to ensure state is updated before focusing the input
    setTimeout(() => {
      // Try to focus the input element if it exists
      if (newState[parameterId]) {
        const searchInput = document.querySelector(`#filter-search-input-${parameterId}`);
        if (searchInput) {
          console.log(`Focusing search input for parameter ${parameterId}`);
          searchInput.focus();
        } else {
          console.log(`Could not find search input for parameter ${parameterId}`);
        }
      }
    }, 50);
  };

  // Handle filter search input change
  const handleFilterSearchChange = (parameterId, searchTerm) => {
    setFilterSearchTerms(prev => ({
      ...prev,
      [parameterId]: searchTerm
    }));
  };

  // Filter values based on search term
  const filterValues = (values, parameterId) => {
    const searchTerm = filterSearchTerms[parameterId] || '';
    if (!searchTerm) return values;
    
    const searchTermArray = searchTerm.toLowerCase()
      .split(/\s+/)
      .filter(term => term.length > 0)
      .map(term => term.replace(/[-_]/g, ''));
    
    if (searchTermArray.length === 0) return values;
    
    return values.filter(value => {
      const cleanedValueName = value.ValueName.toLowerCase().replace(/[-_]/g, '');
      return searchTermArray.some(term => cleanedValueName.includes(term));
    });
  };

  const debouncedCategorySearch = useRef(debounce(async (value) => {
    if (value.length < 2 || specificSite !== "digikey.com") return;
    
    try {
      // Get categories using the service
      const categoryIds = await digiKeyService.getCategories(value, 6);
      
      if (categoryIds && categoryIds.length > 0) {
        // Always clear category options before adding new ones
        setCategoryOptions([]);
        setShowCategoryOptions(true);
        
        // Get categories one by one to show loading progress
        let firstCategoryLoaded = false;
        for (const categoryId of categoryIds) {
          try {
            // Get category name from backend
            const categoryDetail = await digiKeyService.getCategoryInfo(categoryId);
            
            // Get sample product to display image
            const product = await digiKeyService.getCategoryProducts(categoryId);
            
            // Add to category options
            setCategoryOptions(prev => [
              ...prev, 
              {
                id: categoryId,
                name: categoryDetail.category_name,
                image: product?.image,
              }
            ]);
            
            // Store the product for this category
            setCategoryProducts(prev => ({
              ...prev,
              [categoryId]: product
            }));
            
            // Remove loading spinner after first category is loaded
            if (!firstCategoryLoaded) {
              setLocalIsLoading(false);
              setIsLoadingLong(false);
              setIsLoadingLocal(false);
              firstCategoryLoaded = true;
            }
          } catch (error) {
            console.error(`Error fetching details for category ${categoryId}:`, error);
          }
        }
      }
    } catch (error) {
      console.error("Error in debounced category search:", error);
    }
  }, 800)).current;

  // DISABLE auto-search as typing - Only trigger when explicitly called
  // Effect for debounced search - disable this to prevent auto-search as typing
  useEffect(() => {
    // DISABLE: Never automatically trigger search on typing
    return;
  }, [debouncedSearchInput, isFormSubmitted]);

  // Keep localRecommendations in sync with the recommendations prop
  useEffect(() => {
    if (recommendations) {
      setLocalRecommendations(recommendations);
    }
  }, [recommendations]);

  // Modified useEffect to show the alert when recommendations are loaded EVERY time
  useEffect(() => {
    // Only proceed if there are recommendations
    if (recommendations && recommendations.length > 0) {
      console.log('Showing alpha release alert');
      // Show the alert with a small delay to ensure UI has updated
      setTimeout(() => {
        alert(`Component Finder is currently in beta. If you're interested in being notified when the full version launches, please click the "Sign Up for Alpha Release" link in the header.`);
        console.log('Alert shown');
      }, 1000);
    }
  }, [recommendations]); // Only run when recommendations change

  const resetStates = () => {
    setSearchInput(""); // Clear the input field
    setSuggestions([]); // Remove the suggestions
    setIsOutsideClicked(false); // Reset the outside click state
    setCategoryOptions([]); // Clear category options
    setShowCategoryOptions(false); // Hide category options
    setIsFormSubmitted(false); // Explicitly reset form submission state
    
    // Reset workflow states
    setWorkflowStep(1);
    setSelectedCategory(null);
    setModalSelectedFilters({});
    setFinalQuery(''); // Ensure final query is cleared when resetting states
  };

  // Function to clear categories - can be used in multiple places
  const clearCategories = () => {
    setCategoryOptions([]);
    setShowCategoryOptions(false);
  };

  // Helper function to update recommendations
  const updateRecommendations = (newRecommendations) => {
    if (isDeepSearchInitiated) {
      // Append new recommendations to existing ones
      setRecommendations(prevRecommendations => {
        const updatedRecommendations = [...prevRecommendations, ...newRecommendations];
        // Also update local recommendations to match
        setLocalRecommendations(updatedRecommendations);
        return updatedRecommendations;
      });
    } else {
      // Replace recommendations for normal search
      setRecommendations(newRecommendations);
      // Also update local recommendations to match
      setLocalRecommendations(newRecommendations);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    setSelectedCategoryId(null); // Reset selected category when input changes

    // Reset the outside click state when user types something
    setIsOutsideClicked(false);

    if (value.length === 0) {
      setIsEscPressed(false);
      setAutocompleteSuggestions([]);
      clearCategories(); // Use our dedicated function
      setDebouncedSearchInput('');
    } else {
      // Update the debounced search term but DO NOT trigger search
      setDebouncedSearchInput(value);
      
      // Keep this for other possible uses but don't display the dropdown
      const matches = searchCategories(value);
      setAutocompleteSuggestions(matches);
      
      // Ensure no category options are shown while typing after pressing back
      // This prevents the automatic selection issue
      setShowCategoryOptions(false);
    }
  };

  const handleAutocompleteSuggestionClick = async (suggestion) => {
    // If there are 3 parts, remove the first part before setting the search input
    const parts = suggestion.label.split('>>');
    const displayLabel = parts.length === 3 ? parts.slice(1).join('>>') : suggestion.label;
    setSearchInput(displayLabel);
    setSelectedCategoryId(suggestion.id);
    setAutocompleteSuggestions([]);
    setSpecificSite("digikey.com"); // Force Digi-Key site when selecting a category
    
    setLocalIsLoading(true);
    setIsLoadingLocal(true);
    
    try {
      // Get access token and credentials from Digi-Key
      const authData = await digiKeyService.getAccessTokenAndHeaders();
      if (!authData) {
        throw new Error("Failed to get Digi-Key access token");
      }
      const { token: accessToken, credentials } = authData;

      // Get filters directly from Digi-Key using the same credentials
      const filters = await digiKeyService.getFilterOptions(accessToken, suggestion.id, credentials);
      setCategoryData({
        category_id: suggestion.id,
        category_name: suggestion.label.split('>>')[0].trim(),
        filters: filters
      });
      setSelectedFilters({}); // Reset filters
      setShowFilters(true);

      // Get initial components without filters using the same credentials
      const makeSearchRequest = async () => {
        const headers = digiKeyService.getHeaders(accessToken, credentials);
        let allProducts = [];
        const maxLimit = 50;
        const numRequests = Math.ceil(maxLimit / maxLimit);
        
        // Make multiple API calls in parallel if needed
        const requests = Array.from({ length: numRequests }, (_, index) => {
          const currentLimit = index === numRequests - 1 ? maxLimit % maxLimit || maxLimit : maxLimit;
          const offset = index * maxLimit;
          
          const searchPayload = {
            Limit: currentLimit,
            Offset: offset,
            FilterOptionsRequest: {
              CategoryFilter: [{ Id: suggestion.id.toString() }],
              ParameterFilterRequest: {
                CategoryFilter: { Id: suggestion.id.toString() },
                ParameterFilters: Object.entries(selectedFilters)
                  .filter(([_, values]) => values && values.length > 0)
                  .map(([parameterId, values]) => ({
                    ParameterId: parseInt(parameterId),
                    FilterValues: values.map(value => ({ Id: value.toString() }))
                  }))
              }
            }
          };

          // Use digiKeyService to perform the search
          return digiKeyService.searchProductsWithPayload(searchPayload, headers);
        });

        // Wait for all requests to complete
        const responses = await Promise.all(requests);
        
        // Process responses
        responses.forEach(response => {
          const products = response.data.Products.map(mapDigiKeyProduct);
          allProducts = [...allProducts, ...products];
        });

        return allProducts;
      };

      // Use the same credentials for the search request
      const products = await makeSearchRequest();
      
      // Update recommendations with the products
      updateRecommendations(products);
      onSearchComplete();
      setSearchMade(true);
      setStoredInput(suggestion.label);
      setLastSearchInput(suggestion.label);
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true }));
    } catch (error) {
      console.error("Error in autocomplete suggestion click:", error);
    } finally {
      setLocalIsLoading(false);
      setIsLoadingLocal(false);
    }
  };

  const handleSearch = async (searchValue, isDeepSearch = false, limit = 40) => {
    // Clear any existing categories
    setCategoryOptions([]);
    setShowCategoryOptions(false);
    
    // Update state for search
    setIsDeepSearchInitiated(isDeepSearch);
    const startTime = new Date().getTime();
    
    // Set loading states
    setLocalIsLoading(true);
    setIsLoadingLocal(true);
    setIsLoadingLong(true);
    setLocalRecommendations([]);
    setStoredInput(searchValue);
    setRecommendations([]);
    setIsLoadingLongLocal(true);
    setIsLoadingCategories(true); // Set categories loading state

    try {
      if (specificSite === "digikey.com") {
        if (!selectedCategoryId) {
          // Open the workflow modal and start with step 1
          setInitialComponentDescription(searchValue);
          setIsWorkflowModalOpen(true);
          setWorkflowStep(1);
          setFinalQuery(''); // Clear final query when opening the modal
          
          // Initialize with empty category options
          setCategoryOptions([]);
          
          // Set loading to false early since we'll show categories as they load
          setLocalIsLoading(false);
          setIsLoadingLong(false);
          setIsLoadingLocal(false);
          
          // Fetch categories for the modal in the background
          const fetchCategories = async () => {
            try {
              const categoryIds = await digiKeyService.getCategories(searchValue, 6);
              
              if (categoryIds && categoryIds.length > 0) {
                // For each category ID, fetch and display as soon as it's available
                categoryIds.forEach(async (categoryId, index) => {
                  try {
                    // Get category name from backend
                    const categoryDetail = await digiKeyService.getCategoryInfo(categoryId);
                    
                    // Get sample product to display image
                    const product = await digiKeyService.getCategoryProducts(categoryId);
                    
                    // Add to category options as soon as it's available
                    setCategoryOptions(prev => [
                      ...prev, 
                      {
                        id: categoryId,
                        name: categoryDetail.category_name,
                        image: product?.image,
                        isLoading: false
                      }
                    ]);
                    
                    // Store the product for this category
                    setCategoryProducts(prev => ({
                      ...prev,
                      [categoryId]: product
                    }));
                  } catch (error) {
                    console.error(`Error fetching details for category ${categoryId}:`, error);
                  }
                });
              }
            } catch (error) {
              console.error("Error fetching category IDs:", error);
            } finally {
              setIsLoadingCategories(false);
              setIsFormSubmitted(false);
            }
          };
          
          // Start the async category loading process
          fetchCategories();
          
          return;
        }
      }

      let categoryId = selectedCategoryId;
      let categoryName = searchValue.split('>>')[0].trim();
      
      // Get access token and credentials from Digi-Key
      const authData = await digiKeyService.getAccessTokenAndHeaders();
      if (!authData) {
        throw new Error("Failed to get Digi-Key access token");
      }
      const { token: accessToken, credentials } = authData;

      // Get filters directly from Digi-Key using the same credentials
      const filters = await digiKeyService.getFilterOptions(accessToken, categoryId, credentials);
      setCategoryData({
        category_id: categoryId,
        category_name: categoryName,
        filters: filters
      });
      setSelectedFilters({}); // Reset filters
      setShowFilters(true);

      // Get initial components without filters
      const products = await digiKeyService.searchProducts(categoryId);
      
      // Update recommendations with the products
      updateRecommendations(products);
      onSearchComplete();
      setSearchMade(true);
      setStoredInput(categoryName);
      setLastSearchInput(categoryName);
      setSearchInput(""); // Clear the search input after successful search
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true }));
    } catch (error) {
      console.error("Error fetching product recommendations:", error);
    } finally {
      setLocalIsLoading(false);
      setIsLoadingLong(false);
      setIsLoadingLocal(false);
      setIsFormSubmitted(false);
      setIsEscPressed(false);
      setIsLoadingCategories(false); // Make sure loading state is cleaned up
      
      // Calculate search duration
      const searchEndTime = Date.now();
      const duration = (searchEndTime - startTime) / 1000; // in seconds
    }
  };

  const fetchSuggestions = async (value) => {
    if (isFetchingSuggestions || isEscPressed) return;
    setIsFetchingSuggestions(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/suggestions`, {
        params: {
          partial_input: value,
          num_suggestions: 5,
        },
      });
      if (!isFormSubmitted) {
        setSuggestions(response.data.suggestions || []); // Ensure suggestions is an array
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]); // Set suggestions to an empty array on error
    } finally {
      setIsFetchingSuggestions(false);
    }
  };

  const debouncedFetchSuggestions = useRef(debounce(fetchSuggestions, 300)).current;

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setSuggestions([]);
    handleSearch(suggestion); // Confirm that the suggestion will be used as the product to search for
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (searchInput.trim()) {
      // Set form as submitted to trigger the category search
      setIsFormSubmitted(true);
      
      // Start the workflow with the initial component description
      setInitialComponentDescription(searchInput);
      handleSearch(searchInput);
    }
  };

  const handleDeepSearchSubmit = (e) => {
    e.preventDefault();
    if (searchInput.trim()) {
      // No event tracking needed
      handleSearch(searchInput, true);
    }
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest('.inputGroup') && !e.target.closest('.workflow-modal')) {
      setSuggestions([]);
      setIsOutsideClicked(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setSuggestions([]);
      setIsEscPressed(true);
      // Also hide category options on Escape
      setShowCategoryOptions(false);
    }
  };

  const handleTextareaKeyDown = (e) => {
    if (e.key === "Enter" && !(e.shiftKey || e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      if (activeTab === "newSearch") {
        // Only handle search on explicit Enter key press
        handleFormSubmit(e);
      } else if (activeTab === "similarProduct") {
        handleSimilarProductDeepSearchSubmit(e);
      } else if (activeTab === "askQuestion") {
        handleQuestionFormSubmit(e);
      } else if (activeTab === "guidedSearch") {
        handleGuidedSearchFormSubmit(e);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleShowMeClick = () => {
    handleSearch("Show me products based on the last question I asked.");
  };

  const handleSiteButtonClick = (site) => {
    setSpecificSite(site);
  };

  const handleTextareaInput = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight - 20}px`;
  };

  const handleTabClick = (tab) => {
    // No event tracking needed
    setActiveTab(tab);
  };

  // Handle closing the modal
  const handleCloseWorkflowModal = () => {
    setIsWorkflowModalOpen(false);
    setWorkflowStep(1);
    setLocalIsLoading(false);
    setIsLoadingLong(false);
    setIsLoadingLocal(false);
    
    // Reset all modal data
    setSelectedCategory(null);
    setModalSelectedFilters({});
    setFinalQuery(''); // Ensure final query is cleared when closing the modal
    setShowFilterSearch({});
    setFilterSearchTerms({});
    setIsLoadingFilters(false);
    setIsLoadingCategories(false);
    setCategoryOptions([]);
  };

  // Handle selecting a category in the workflow modal
  const handleWorkflowCategorySelect = async (categoryId, categoryName) => {
    // Just set the selected category but don't load filters yet
    setSelectedCategory({
      id: categoryId,
      name: categoryName
    });
  };

  // Load filters when moving to the next step
  const handleNextStep = async () => {
    if (workflowStep === 1 && selectedCategory) {
      // Set loading state BEFORE any async operations
      setIsLoadingFilters(true);
      setWorkflowStep(2); // Advance to the next step
      
      try {
        // Get filters for selected category
        const authData = await digiKeyService.getAccessTokenAndHeaders();
        if (!authData) {
          throw new Error("Failed to get Digi-Key access token");
        }
        const { token: accessToken, credentials } = authData;

        // Get filters directly from Digi-Key
        const filters = await digiKeyService.getFilterOptions(accessToken, selectedCategory.id, credentials);
        
        // Store the category data
        setCategoryData({
          category_id: selectedCategory.id,
          category_name: selectedCategory.name,
          filters: filters
        });
        
        // Reset filters
        setModalSelectedFilters({});
      } catch (error) {
        console.error("Error fetching category filters:", error);
      } finally {
        // Always set loading to false when done, but with a small delay to ensure UI updates properly
        setTimeout(() => {
          setIsLoadingFilters(false);
        }, 300);
      }
    } else if (workflowStep < 3) {
      // For other steps, just advance normally
      setWorkflowStep(workflowStep + 1);
    }
  };

  // Handle filter changes in the modal
  const handleModalFilterChange = (filterName, values) => {
    setModalSelectedFilters(prev => ({
      ...prev,
      [filterName]: values
    }));
  };

  // Handle navigation between steps
  const handlePrevStep = () => {
    if (workflowStep > 1) {
      setWorkflowStep(workflowStep - 1);
    }
  };

  // Handle final query input change
  const handleFinalQueryChange = (e) => {
    setFinalQuery(e.target.value);
  };

  // Handle completing the workflow
  const handleCompleteWorkflow = async () => {
    // Show the alert immediately when search button is clicked
    alert("Component Finder is currently in beta. If you're interested in being notified when the full version launches, please click the 'Sign Up for Alpha Release' link in the header.");
    
    if (!selectedCategory) {
      return;
    }
    
    setLocalIsLoading(true);
    setIsWorkflowModalOpen(false);
    
    try {
      // Store query for later use
      const userQuery = finalQuery || initialComponentDescription;
      localStorage.setItem('originalUserQuery', userQuery);
      setStoredInput(userQuery);
      
      // Get products with the selected filters
      const products = await digiKeyService.searchProducts(
        selectedCategory.id, 
        modalSelectedFilters,
        50 // Limit to 50 products
      );
      
      // Update state with the data
      setSelectedCategoryId(selectedCategory.id);
      setSearchInput(""); // Clear the search input
      setFinalQuery(''); // Clear final query after completing workflow
      setSelectedFilters(modalSelectedFilters);
      
      // Update recommendations
      updateRecommendations(products);
      onSearchComplete();
      setSearchMade(true);
      setLastSearchInput(userQuery);
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true }));
    } catch (error) {
      console.error("Error completing workflow:", error);
    } finally {
      setLocalIsLoading(false);
      setWorkflowStep(1);
    }
  };

  // Skip the API call to generate filter rules - user will input rules directly
  // This function is kept as a placeholder for compatibility
  const generateFilterRules = async (userInput, categoryName, filters) => {
    console.warn("generateFilterRules is no longer used");
    return {};
  };

  // This function is kept as a placeholder for compatibility
  const applyFilterRules = async (filters, filterRules, userInput) => {
    console.warn("applyFilterRules is deprecated");
    return {};
  };

  // Handle filter changes (needed for Filters component)
  const handleFilterChange = (filterName, values) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterName]: values
    }));
  };

  // Handle applying filters (needed for Filters component)
  const handleApplyFilters = async (newFilters = null) => {
    setIsLoadingLong(true);
    try {
      // Use the new filters if provided, otherwise use current selectedFilters
      const filtersToUse = newFilters || selectedFilters;
      
      // Get products using the service
      const products = await digiKeyService.searchProducts(
        categoryData.category_id, 
        filtersToUse, 
        50 // Limit to 50 products
      );

      // Update the filters state with the new filters if they were provided
      if (newFilters) {
        setSelectedFilters(newFilters);
      }

      setRecommendations(products);
    } catch (error) {
      console.error("Error applying filters:", error);
    } finally {
      setIsLoadingLong(false);
      setIsModalOpen(false);
    }
  };

  // Handle clearing all filters (needed for Filters component)
  const handleClearAll = () => {
    // Create empty filters object
    const emptyFilters = {};
    if (categoryData && categoryData.filters) {
      categoryData.filters.forEach(filter => {
        emptyFilters[filter.ParameterId] = [];
      });
      
      // Update the filters state
      setSelectedFilters(emptyFilters);
      
      // Apply the empty filters immediately
      handleApplyFilters(emptyFilters);
    }
  };

  // Handle going back from filters view (needed for Filters component)
  const handleBack = () => {
    // Reset recommendations
    setRecommendations([]);
    
    // Reset all filter and category-related states
    setSelectedFilters({});
    setShowFilters(false);
    setSearchInput("");
    setSelectedCategoryId(null);
    setCategoryOptions([]);
    setShowCategoryOptions(false);
    setCategoryData(null);
    
    // Reset form states
    setIsFormSubmitted(false);
    
    // Clear any stored data that might be used to auto-populate or select categories
    setDebouncedSearchInput("");
    
    // Ensure we reset to initial UI state
    resetStates();
  };

  // Deep search function (needed for Filters component)
  const handleDeepSearch = async (userInput, requirements = '', productDetails = null) => {
    // If no user input is provided, use the stored input from props or the current search input
    const queryInput = userInput || storedInput || searchInput;
    
    if (!queryInput) {
      console.error("No input provided for deep search");
      return;
    }
    
    console.log("Starting deep search for query:", queryInput, "with requirements:", requirements);
    setIsDeepSearchInitiated(true);
    setIsLoadingLong(true);
    
    try {
      // Make sure product_details is a JSON string for the API call
      let productDetailsStr = null;
      
      // Debug which products we're using
      if (productDetails) {
        // Check if productDetails is already a string
        if (typeof productDetails === 'string') {
          console.log("Using provided product details string");
          productDetailsStr = productDetails;
          try {
            // Verify we can parse it as JSON
            const parsed = JSON.parse(productDetails);
            console.log(`Contains ${parsed.length} products`);
          } catch (e) {
            console.warn("Product details is not valid JSON:", e);
          }
        } 
        // Check if it's an array we need to stringify
        else if (Array.isArray(productDetails)) {
          console.log(`Using provided product details array with ${productDetails.length} products`);
          productDetailsStr = JSON.stringify(productDetails);
        }
        // Unknown format
        else {
          console.warn("Product details is in an unexpected format:", typeof productDetails);
        }
      }
      
      // If we still don't have product details, try to use available recommendations
      if (!productDetailsStr) {
        console.log("No valid product details provided, trying available recommendations");
        
        // Try to use parent recommendations if available
        if (parentRecommendations && parentRecommendations.length > 0) {
          productDetailsStr = JSON.stringify(parentRecommendations);
          console.log(`Using ${parentRecommendations.length} parent recommendations`);
        } 
        // Fall back to local recommendations
        else if (localRecommendations && localRecommendations.length > 0) {
          productDetailsStr = JSON.stringify(localRecommendations);
          console.log(`Using ${localRecommendations.length} local recommendations`);
        }
        // Empty array as last resort
        else {
          productDetailsStr = JSON.stringify([]);
          console.log("No recommendations found, using empty array");
        }
      }
      
      // Log the first part of the product details to verify it's being sent
      console.log("Sending product_details:", productDetailsStr.substring(0, 100) + "...");
      
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/deep_search`, {
        params: {
          user_input: queryInput,
          session_id: sessionId,
          specific_site: specificSite,
          requirements: requirements || '',
          product_details: productDetailsStr
        }
      });
      
      if (response.data && response.data.recommendations) {
        updateRecommendations(response.data.recommendations);
        console.log("Deep search completed with", response.data.recommendations.length, "results");
      } else {
        console.warn("Deep search returned no recommendations");
      }
    } catch (error) {
      console.error("Error during deep search:", error);
      if (error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", error.response.data);
      }
    } finally {
      setIsDeepSearchInitiated(false);
      setIsLoadingLong(false);
    }
  };

  // Placeholder functions for compatibility with the form submission
  const handleSimilarProductFormSubmit = (e) => {
    e.preventDefault();
    console.warn("Similar product search is not implemented in the new workflow");
  };

  const handleSimilarProductDeepSearchSubmit = (e) => {
    e.preventDefault();
    console.warn("Similar product deep search is not implemented in the new workflow");
  };

  const handleQuestionFormSubmit = (e) => {
    e.preventDefault();
    console.warn("Question form is not implemented in the new workflow");
  };

  const handleGuidedSearchFormSubmit = (e) => {
    e.preventDefault();
    console.warn("Guided search is not implemented in the new workflow");
  };

  // If there are recommendations and we're not showing filters, don't render the ProductForm at all
  // Instead, just render the header with the back button and category/search info
  if (recommendations && recommendations.length > 0 && !showFilters) {
    return (
      <div className="productFormContainer results-active">
        <div className="filters-header">
          <div className="filters-header-left" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={handleBack}>
              <FaArrowLeft /> Back
            </button>
            <h2 style={{ margin: '0 0 0 10px' }}>
              {lastSearchInput || storedInput || "Search Results"}
            </h2>
          </div>
        </div>
      </div>
    );
  }

  // Regular ProductForm UI for searching or showing filters
  return (
    <div className={`productFormContainer ${showFilters ? 'filters-active' : ''}`}>
      {!showFilters ? (
        <>
          <div className="tabs" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
            {/* Tabs have been commented out as per design */}
          </div>
          <form onSubmit={activeTab === "newSearch" ? handleFormSubmit : activeTab === "similarProduct" ? handleSimilarProductFormSubmit : activeTab === "askQuestion" ? handleQuestionFormSubmit : handleGuidedSearchFormSubmit} className="form">
            {activeTab === "newSearch" && (
              <div className="inputGroup">
                <label className="label" style={{ marginTop: '10px' }}>
                  <FaSearch className="icon" /> Find a DigiKey Component:
                </label>
                <p className="form-description">Briefly describe the component you're looking for</p>
                <textarea
                  value={searchInput}
                  onChange={(e) => { handleInputChange(e); handleTextareaInput(e); }}
                  className="input"
                  required
                  rows="1"
                  placeholder={searchMadeInTabs.newSearch ? "" : 'Voltage regulator, 3.3V output, 1A, -40C to 125C'}
                  onKeyDown={(e) => { handleKeyDown(e); handleTextareaKeyDown(e); }}
                  disabled={showFilters}
                />
              </div>
            )}
            <div className="buttonGroup" style={{ display: 'flex', gap: '10px', marginTop: '15px' }}>
              {activeTab === "newSearch" && (
                <button type="submit" className="button" disabled={isLoadingLocal}>Search</button>
              )}
            </div>
            {description && activeTab !== "askQuestion" && (
              <div className="response-container">
                <div className="search-duration">Thought for {searchDuration} seconds...</div>
                <ReactMarkdown>{description}</ReactMarkdown>
                <button onClick={handleShowMeClick} className="button" style={{ marginBottom: "15px" }}>Show me</button>
              </div>
            )}
          </form>
        </>
      ) : (
        showFilters && categoryData && specificSite === "digikey.com" && (
          <Filters
            categoryData={categoryData}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onApplyFilters={handleApplyFilters}
            onClearAll={handleClearAll}
            onDeepSearch={handleDeepSearch}
            onBack={handleBack}
            isLoadingLocal={isLoadingLocal}
            setRecommendations={setRecommendations}
            categoryName={searchInput.trim()}
            setIsLoadingLong={setIsLoadingLong}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recommendations={localRecommendations}
          />
        )
      )}

      {/* Multi-step workflow modal */}
      {isWorkflowModalOpen && (
        <div className="workflow-modal-overlay">
          <div className="workflow-modal">
            <div className="workflow-modal-header">
              <h2>
                {workflowStep === 1 ? "Select a Category" : 
                 workflowStep === 2 ? "Select Filters" : 
                 "Input Your Specs"}
              </h2>
              <button className="close-button" onClick={handleCloseWorkflowModal}>
                <FaTimes />
              </button>
            </div>
            
            {/* Remove progress indicator as requested */}
            
            <div className="workflow-modal-content">
              {/* Step 1: Category Selection */}
              {workflowStep === 1 && (
                <div className="category-selection">
                  <p>Select a category for: <strong>{initialComponentDescription}</strong></p>
                  
                  <div className="modal-categories-grid">
                    {categoryOptions.length === 0 ? (
                      <div className="centered-spinner-container">
                        <div className="centered-spinner"></div>
                        <p>Finding categories...</p>
                      </div>
                    ) : (
                      categoryOptions.map((category) => (
                        <div 
                          key={category.id} 
                          className={`modal-category-option ${selectedCategory?.id === category.id ? 'selected' : ''}`}
                          onClick={() => handleWorkflowCategorySelect(category.id, category.name)}
                        >
                          <div className="modal-category-image-container">
                            {category.image ? (
                              <img src={category.image} alt={category.name} className="modal-category-image" />
                            ) : (
                              <div className="no-image-placeholder">No Image</div>
                            )}
                          </div>
                          <div className="modal-category-name">{category.name}</div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
              
              {/* Step 2: Filter Selection */}
              {workflowStep === 2 && (
                <div className="filter-selection">
                  <p>Select filters for <strong>{selectedCategory?.name}</strong>:</p>
                  
                  {/* Always show the loading spinner until categoryData is ready AND isLoadingFilters is false */}
                  {(isLoadingFilters || !categoryData) ? (
                    <div className="centered-spinner-container">
                      <div className="centered-spinner"></div>
                      <p>Loading filters...</p>
                    </div>
                  ) : (
                    <div className="modal-filters-container">
                      {categoryData.filters.map((filter) => {
                        // Get filtered values
                        const filteredValues = filterValues(filter.FilterValues, filter.ParameterId);
                        
                        return (
                          <div key={filter.ParameterId} className="modal-filter-group">
                            <div className="filter-header">
                              <h3>{filter.ParameterName}</h3>
                              {((modalSelectedFilters[filter.ParameterId]?.length || 0) > 0) && !showFilterSearch[filter.ParameterId] && (
                                <span className="selected-count">
                                  {modalSelectedFilters[filter.ParameterId]?.length || 0} selected
                                </span>
                              )}
                              
                              {showFilterSearch[filter.ParameterId] ? (
                                <div className="search-input-container-inline">
                                  <FaSearch className="search-icon-inline" />
                                  <input
                                    id={`filter-search-input-${filter.ParameterId}`}
                                    type="text"
                                    placeholder="Search..."
                                    value={filterSearchTerms[filter.ParameterId] || ''}
                                    onChange={(e) => handleFilterSearchChange(filter.ParameterId, e.target.value)}
                                    className="filter-search-input-inline"
                                    autoFocus
                                    onBlur={() => {
                                      // Only hide if there's no search term
                                      if (!filterSearchTerms[filter.ParameterId]) {
                                        setTimeout(() => setShowFilterSearch(prev => ({...prev, [filter.ParameterId]: false})), 200);
                                      }
                                    }}
                                  />
                                  <button 
                                    className="filter-search-close"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowFilterSearch(prev => ({...prev, [filter.ParameterId]: false}));
                                      setFilterSearchTerms(prev => ({...prev, [filter.ParameterId]: ''}));
                                    }}
                                  >
                                    <FaTimes />
                                  </button>
                                </div>
                              ) : (
                                <button 
                                  className="filter-search-toggle"
                                  onClick={() => toggleFilterSearch(filter.ParameterId)}
                                >
                                  <FaSearch />
                                </button>
                              )}
                            </div>
                            
                            <div className="filter-options">
                              {filteredValues.map((value) => {
                                const isSelected = (modalSelectedFilters[filter.ParameterId] || []).includes(value.ValueId);
                                
                                return (
                                  <div
                                    key={value.ValueId}
                                    className={`filter-option ${isSelected ? 'selected' : ''}`}
                                    onClick={() => {
                                      const currentValues = modalSelectedFilters[filter.ParameterId] || [];
                                      const newValues = isSelected
                                        ? currentValues.filter(id => id !== value.ValueId)
                                        : [...currentValues, value.ValueId];
                                      
                                      handleModalFilterChange(filter.ParameterId, newValues);
                                    }}
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      maxWidth: '100%'
                                    }}
                                    title={value.ValueName}
                                  >
                                    {value.ValueName}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              
              {/* Step 3: Final Query */}
              {workflowStep === 3 && (
                <div className="final-query">
                  <p>What information would you like to see for the components? All information will be pulled directly from datasheets, and synthesized by AI.</p>
                  
                  <textarea
                    value={finalQuery}
                    onChange={handleFinalQueryChange}
                    className="final-query-input"
                    placeholder="Show me thermal specs and power ratings"
                    rows="4"
                  />
                </div>
              )}
            </div>
            
            <div className="workflow-modal-footer">
              {workflowStep > 1 && (
                <button className="button secondary" onClick={handlePrevStep}>
                  Back
                </button>
              )}
              
              {workflowStep < 3 && (
                <button 
                  className="button primary" 
                  onClick={handleNextStep}
                  disabled={workflowStep === 1 && !selectedCategory}
                >
                  Next
                </button>
              )}
              
              {workflowStep === 3 && (
                <button 
                  className="button primary"
                  onClick={handleCompleteWorkflow}
                >
                  Search
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductForm;
