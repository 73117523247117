// src/components/ProductList.js
import React, { useState, useEffect, useRef } from "react";
import "./ProductList.css";
// Keep ReactGA import for consistent imports, but we won't use it for events
import ReactGA from 'react-ga4';
import axios from 'axios';

// Simple markdown parser to handle basic formatting
const renderMarkdown = (text) => {
  if (!text) return '';
  
  // Split text by newlines to process each line individually
  const lines = text.split('\n');
  const processedLines = lines.map(line => {
    const trimmedLine = line.trim();
    if (!trimmedLine) return '';
    
    // Don't add bullet points to lines that are already headings
    if (trimmedLine.startsWith('#')) {
      // Format headings (# Heading)
      if (trimmedLine.startsWith('### ')) return `<h3>${trimmedLine.substring(4)}</h3>`;
      if (trimmedLine.startsWith('## ')) return `<h2>${trimmedLine.substring(3)}</h2>`;
      if (trimmedLine.startsWith('# ')) return `<h1>${trimmedLine.substring(2)}</h1>`;
    }
    
    // Skip adding bullet points to lines that already have them
    if (trimmedLine.startsWith('- ') || trimmedLine.startsWith('* ')) {
      return `<li>${trimmedLine.substring(2)}</li>`;
    }
    
    // Skip adding bullet points to numbered list items
    if (/^\d+\.\s/.test(trimmedLine)) {
      return `<li>${trimmedLine.replace(/^\d+\.\s/, '')}</li>`;
    }
    
    // Make every other line a paragraph with bullet point styling
    return `<p>${trimmedLine}</p>`;
  });
  
  // Join the processed lines
  let html = processedLines.join('');
  
  // Format bold text
  html = html.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');
  html = html.replace(/\_\_(.+?)\_\_/g, '<strong>$1</strong>');
  
  // Format italic text
  html = html.replace(/\*(.+?)\*/g, '<em>$1</em>');
  html = html.replace(/\_(.+?)\_/g, '<em>$1</em>');
  
  return html;
};

const ProductList = ({ recommendations, setRecommendations, sessionId, userQuery, setDescription, showSearchResultsTitle=true, setIsLoadingLong, isLoadingLong }) => {
  const [topChoiceIndex, setTopChoiceIndex] = useState(null);
  // Store summaries as a simple object with product IDs as keys
  const [datasheetSummaries, setDatasheetSummaries] = useState({});
  // Track which products are currently being processed
  const [summarizingIds, setSummarizingIds] = useState([]);
  // Track which products have failed to process
  const [failedIds, setFailedIds] = useState([]);
  // Track batch processing status
  const [isProcessingBatch, setIsProcessingBatch] = useState(false);
  const [totalToProcess, setTotalToProcess] = useState(0);
  const [processed, setProcessed] = useState(0);
  
  // Keep track of previous recommendations to detect significant changes
  const previousRecommendationsRef = useRef([]);
  
  // Log when userQuery changes to verify it's being passed correctly
  useEffect(() => {
    if (userQuery) {
      console.log("ProductList received userQuery:", userQuery);
    }
  }, [userQuery]);
  
  // Batch size for processing datasheets
  const BATCH_SIZE = 10;
  
  // This ref will keep track of the current processing state 
  // even if the component re-renders
  const pendingProductsRef = useRef([]);
  
  // Helper function to check if recommendations have changed significantly
  const haveRecommendationsChangedSignificantly = (prevRecs, currentRecs) => {
    // If more than 50% of the IDs are different, consider it a significant change
    if (prevRecs.length === 0 || currentRecs.length === 0) return true;
    
    const prevIds = new Set(prevRecs.map(p => p.id));
    const currentIds = new Set(currentRecs.map(p => p.id));
    
    // Calculate how many IDs are in current but not in previous
    let newItemCount = 0;
    currentIds.forEach(id => {
      if (!prevIds.has(id)) newItemCount++;
    });
    
    // If more than 50% are new or the total count changed significantly, it's a big change
    return newItemCount > (currentRecs.length * 0.5) || 
           Math.abs(prevRecs.length - currentRecs.length) > Math.min(3, prevRecs.length * 0.3);
  };
  
  // Reset summary states when recommendations change significantly
  useEffect(() => {
    // Check if recommendations have changed significantly
    if (haveRecommendationsChangedSignificantly(previousRecommendationsRef.current, recommendations)) {
      console.log("Recommendations changed significantly, resetting summary states");
      
      // Reset all summary-related states if recommendations changed significantly
      setDatasheetSummaries({});
      setSummarizingIds([]);
      setFailedIds([]);
      setIsProcessingBatch(false);
      pendingProductsRef.current = [];
    }
    
    // Update the previous recommendations reference
    previousRecommendationsRef.current = [...recommendations];
    
    if (recommendations.length > 0) {
      const randomIndex = Math.floor(Math.random() * recommendations.length);
      setTopChoiceIndex(randomIndex);
      
      // Find all products with datasheets that need processing
      const productsWithDatasheets = recommendations.filter(
        product => product.datasheet_link && 
        !datasheetSummaries[product.id] && 
        !summarizingIds.includes(product.id) &&
        !failedIds.includes(product.id)
      );
      
      if (productsWithDatasheets.length > 0) {
        console.log(`Found ${productsWithDatasheets.length} products with datasheets to process`);
        
        // Store the products to process
        pendingProductsRef.current = [...productsWithDatasheets];
        setTotalToProcess(productsWithDatasheets.length);
        setProcessed(0);
        
        // Start batch processing if it's not already running
        if (!isProcessingBatch) {
          setIsProcessingBatch(true);
          processBatch();
        }
      }
    }
  }, [recommendations, datasheetSummaries, summarizingIds, failedIds, isProcessingBatch]);
  
  // Process a batch of datasheets
  const processBatch = async () => {
    // If there are no more products to process, stop
    if (pendingProductsRef.current.length === 0) {
      setIsProcessingBatch(false);
      return;
    }
    
    // Use a smaller batch size for TE.com products
    const hasTEProducts = pendingProductsRef.current.some(
      product => product.datasheet_link && product.datasheet_link.includes('te.com')
    );
    
    // Adjust batch size based on presence of TE.com products
    const currentBatchSize = hasTEProducts ? Math.min(3, BATCH_SIZE) : BATCH_SIZE;
    
    // Take the next batch with adjusted size
    const currentBatch = pendingProductsRef.current.slice(0, currentBatchSize);
    pendingProductsRef.current = pendingProductsRef.current.slice(currentBatchSize);
    
    console.log(`Processing batch of ${currentBatch.length} datasheets. ${pendingProductsRef.current.length} remaining.`);
    
    // Process all products in the current batch concurrently
    const batchIds = currentBatch.map(product => product.id);
    setSummarizingIds(prev => [...prev, ...batchIds]);
    
    try {
      // Create all the promises but don't await them yet
      const promises = currentBatch.map(product => 
        fetchDatasheetSummary(product.id, product.datasheet_link, true)
      );
      
      // Wait for all of them to complete
      const results = await Promise.allSettled(promises);
      
      // Log results
      const successCount = results.filter(result => result.status === 'fulfilled' && result.value).length;
      console.log(`Batch processed with ${successCount}/${results.length} successful summaries`);
      
      // Update the processed count
      setProcessed(prev => prev + currentBatch.length);
      
      // Remove these IDs from summarizing list
      setSummarizingIds(prev => prev.filter(id => !batchIds.includes(id)));
      
      // Calculate a delay based on the current batch contents
      // Add extra delay if there were te.com products
      const baseDelay = hasTEProducts ? 2000 : 500;
      const failureDelay = results.filter(r => r.status === 'rejected').length * 500;
      const totalDelay = baseDelay + failureDelay;
      
      // Process the next batch with a calculated delay
      setTimeout(() => {
        processBatch();
      }, totalDelay);
    } catch (error) {
      console.error("Error processing batch:", error);
      // Remove IDs from summarizing list regardless of error
      setSummarizingIds(prev => prev.filter(id => !batchIds.includes(id)));
      
      // Continue with the next batch with a longer delay on error
      setTimeout(() => {
        processBatch();
      }, 2000);
    }
  };

  const handleViewProduct = async (productId, productTitle, productLink, productSummary) => {
    if (productSummary || productLink) {
      window.open(productLink, '_blank');
      return;
    }
    setIsLoadingLong(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get_link?session_id=${sessionId}&product_id=${productId}`);
      const data = await response.json();
      
      if (data.additional_link) {
        if (data.additional_link.includes('digikey.com')) {
          window.open(data.additional_link, '_blank');
        } else {
          window.open(`https://www.google.com/search?tbm=shop&q=${encodeURIComponent(productTitle)}`, '_blank');
        }
      } else {
        window.open(`https://www.google.com/search?tbm=shop&q=${encodeURIComponent(productTitle)}`, '_blank');
      }
    } catch (error) {
      console.error("Error fetching product link:", error);
      alert("An error occurred while fetching the product link.");
    } finally {
      setIsLoadingLong(false);
    }
  };

  // Function to fetch a datasheet summary for a single product
  const fetchDatasheetSummary = async (productId, datasheetUrl, isBatch = false) => {
    // Skip if already summarizing or failed (when not part of a batch)
    if (!datasheetUrl || (!isBatch && summarizingIds.includes(productId))) {
      return null;
    }

    // Only mark as processing if not part of a batch (batch handling is done elsewhere)
    if (!isBatch) {
      setSummarizingIds(prev => [...prev, productId]);
    }
    
    // Make sure we have the exact user query
    // This is CRITICAL: Try all possible sources to get the original user query
    let actualQuery = userQuery;
    
    // If somehow userQuery is missing despite our fixes, try localStorage backup
    if (!actualQuery || actualQuery === '') {
      actualQuery = localStorage.getItem('originalUserQuery');
      console.warn("Had to fall back to localStorage for user query:", actualQuery);
    }
    
    // If we still don't have it, use a general fallback but log the error
    if (!actualQuery || actualQuery === '') {
      console.error("CRITICAL ERROR: Could not retrieve user's original query for datasheet summary!");
      actualQuery = "Show detailed specifications and important features of this component";
    }
    
    console.log(`Fetching summary for product ${productId} using query: "${actualQuery}"`);
    
    try {      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/summarize-datasheet`,
        null,
        {
          params: {
            datasheet_url: datasheetUrl,
            user_query: actualQuery,
            session_id: sessionId
          },
          timeout: 60000 // Increase timeout to 60 seconds
        }
      );
      
      // Only remove from processing if not part of a batch
      if (!isBatch) {
        setSummarizingIds(prev => prev.filter(id => id !== productId));
      }
      
      if (response.data.success && response.data.summary && response.data.summary.trim()) {
        // Store the summary directly with the product ID
        console.log(`Success! Got summary for product ${productId}`);
        setDatasheetSummaries(prev => ({
          ...prev,
          [productId]: response.data.summary
        }));
        
        // Remove from failed IDs if it was there previously
        if (failedIds.includes(productId)) {
          setFailedIds(prev => prev.filter(id => id !== productId));
        }
        
        return response.data.summary;
      } else {
        // Mark as failed
        console.log(`Failed to get summary for product ${productId}: ${response.data.error || 'Unknown error'}`);
        setFailedIds(prev => [...prev, productId]);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching datasheet summary for ${productId}:`, error);
      // Mark as failed
      setFailedIds(prev => [...prev, productId]);
      
      // Only remove from processing if not part of a batch
      if (!isBatch) {
        setSummarizingIds(prev => prev.filter(id => id !== productId));
      }
      return null;
    }
  };

  // Function to handle retry for failed summaries
  const handleRetrySummary = async (productId, datasheetUrl) => {
    // Remove from failed list
    setFailedIds(prev => prev.filter(id => id !== productId));
    
    // Retry the summary fetch
    fetchDatasheetSummary(productId, datasheetUrl, false);
  };

  const uniqueRecommendations = recommendations.filter((product, index, self) =>
    index === self.findIndex((p) => p.title === product.title && p.price === product.price)
  );

  return (
    <div className="wrapper">
      {recommendations.length > 0 && (
        <>
          {showSearchResultsTitle && !recommendations[0]?.vendor?.includes('digikey') && (
            <>
              <h3 className="resultsTitle">Search Results:</h3>
              {userQuery && userQuery.length <= 90 && (
                <p className="searchQuery">
                  Showing results for "{userQuery}"
                </p>
              )}
            </>
          )}
          <div className="container">
            {uniqueRecommendations.map((product, index) => (
              <div key={`${product.id}-${index}`} className="product">
                <h4>{product.title}</h4>
                {product.image ? (
                  <img src={product.image} alt={product.title} className="image" />
                ) : (
                  <div className="no-image-placeholder">No Image Available</div>
                )}
                <p className="price">{product.price}</p>
                <div className="vendorContainer">
                  <p className="vendor">{product.vendor}</p>
                </div>
                
                {/* Description moved up */}
                {product.detailedDescription && !datasheetSummaries[product.id] && (
                  <p className="product-description">
                    {product.detailedDescription}
                  </p>
                )}
                
                {product.datasheet_link && (
                  <div className="datasheet-section">
                    <a href={product.datasheet_link} target="_blank" rel="noopener noreferrer" 
                      className="datasheetLink withPadding">
                      View Datasheet
                    </a>
                    {summarizingIds.includes(product.id) && (
                      <div className="summary-loading">
                        <span>Analyzing datasheet...</span>
                      </div>
                    )}
                    {datasheetSummaries[product.id] && (
                      <div 
                        className="datasheet-summary plain"
                        dangerouslySetInnerHTML={{ __html: renderMarkdown(datasheetSummaries[product.id]) }}
                      />
                    )}
                  </div>
                )}
                <div className="summary">
                  {product.summary && (
                    <ul>
                      {product.summary.split("\n").map((item, idx) => item && <li key={idx}>{item.replace(/^- /, '')}</li>)}
                    </ul>
                  )}
                </div>
                <button 
                  onClick={() => handleViewProduct(product.id, product.title, product.link, product.summary)} 
                  className="link"
                  disabled={isLoadingLong}
                >
                  View Product
                </button>
                <a 
                  href={`https://www.google.com/search?tbm=shop&q=${encodeURIComponent(product.title)}`} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="googleShoppingLink"
                >
                  Find Similar Online
                </a>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductList;
