import axios from 'axios';

// Constants
const API_URL = process.env.REACT_APP_API_URL;
const TOKEN_URL = "https://api.digikey.com/v1/oauth2/token";
const SEARCH_URL = "https://api.digikey.com/products/v4/search/keyword";

// DigiKey API Credentials - Note: These should ideally be moved to environment variables
// or kept entirely on the backend for security
const DIGIKEY_CREDENTIALS = [
    {"client_id": "A3Z0cGLWYiHoeUePkdDsGIXGVlsk3mzX", "client_secret": "3uXhjxlAGTVA2EkV"},
    {"client_id": "PYX1luoCwUHBkSfZ36TZNDIdtKLvn2fy", "client_secret": "ecefzYrE8DqiWAkW"},
    {"client_id": "5MtGt9hBPRd3VvmWv1AWf8KZORjqOaTh", "client_secret": "rXUOiyDWKwDS75V9"},
    {"client_id": "mob9p3RtzEOtyhbakJAiaLAIiGMjCrcF", "client_secret": "ziWWYxd4baQ0Cgxx"},
    {"client_id": "hRHZ9NQyIVHTozPJZtrNW1EtRbDJjNmA", "client_secret": "IIvexhfCzl7rhSxB"},
    {"client_id": "AF2rEvck8ApSjG60DccqHAYRdEB2FwXw", "client_secret": "GHSx3aIMXPuCBHbq"},
    {"client_id": "r7beSdgZDZk7kqmNQrQsaKIC0EDv95hg", "client_secret": "iXPHiKjtJBXfGyuA"},
    {"client_id": "ZRrKyknDgkKDSLWwOJhC5Spw0hJrueSd", "client_secret": "T2PMFXEayDrL9GtD"}
];

// Helper function to get random credentials
const getRandomCredentials = () => {
  const randomIndex = Math.floor(Math.random() * DIGIKEY_CREDENTIALS.length);
  return DIGIKEY_CREDENTIALS[randomIndex];
};

// Helper function to retry API calls with different credentials
const retryWithDifferentCredentials = async (apiCall, maxRetries = 3, currentRetry = 0, lastCredentials = null) => {
  try {
    // Use the same credentials if provided, otherwise get new ones
    const credentials = lastCredentials || getRandomCredentials();
    const result = await apiCall(credentials);
    return result;
  } catch (error) {
    if (error.response?.status === 429 && currentRetry < maxRetries) {
      // Wait for a short time before retrying
      await new Promise(resolve => setTimeout(resolve, 1000 * (currentRetry + 1)));
      // Get new credentials only for rate limit errors
      return retryWithDifferentCredentials(apiCall, maxRetries, currentRetry + 1);
    } else if (error.response?.status === 401) {
      // For auth errors, try with new credentials
      if (currentRetry < maxRetries) {
        const newCredentials = getRandomCredentials();
        return retryWithDifferentCredentials(apiCall, maxRetries, currentRetry + 1, newCredentials);
      }
    }
    throw error;
  }
};

// Get access token and headers
const getAccessTokenAndHeaders = async () => {
  const makeTokenRequest = async (credentials) => {
    const response = await axios.post(
      TOKEN_URL,
      new URLSearchParams({
        grant_type: "client_credentials",
        client_id: credentials.client_id,
        client_secret: credentials.client_secret
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    );
    
    if (response.status === 200) {
      console.log("Access token received");
      return {
        token: response.data.access_token,
        credentials: credentials
      };
    }
    throw new Error(`Failed to get token: ${response.status}`);
  };

  try {
    return await retryWithDifferentCredentials(makeTokenRequest);
  } catch (error) {
    console.error("Error getting token:", error);
    return null;
  }
};

// Set headers for Digi-Key API calls
const getHeaders = (accessToken, credentials) => ({
  "Authorization": `Bearer ${accessToken}`,
  "X-DIGIKEY-Client-Id": credentials.client_id,
  "Content-Type": "application/json",
  "X-DIGIKEY-Locale-Language": "en",
  "X-DIGIKEY-Locale-Currency": "USD",
  "X-DIGIKEY-Locale-Site": "US"
});

// Common product mapping function to ensure consistency
const mapDigiKeyProduct = (p) => ({
  id: crypto.randomUUID(),
  title: p.Description.ProductDescription,
  price: `$${p.UnitPrice}`,
  image: p.PhotoUrl,
  vendor: "digikey.com",
  description: p.Description.DetailedDescription,
  link: p.ProductUrl,
  datasheet: p.DatasheetUrl,
  datasheet_link: p.DatasheetUrl,
  manufacturer: p.Manufacturer.Name,
  manufacturerPartNumber: p.ManufacturerProductNumber,
  detailedDescription: p.Description.DetailedDescription,
  stockStatus: p.QuantityAvailable > 0 ? 'In Stock' : 'Out of Stock',
  quantityAvailable: p.QuantityAvailable
});

// Get filter options for a category
const getFilterOptions = async (accessToken, categoryId, credentials) => {
  try {
    const headers = getHeaders(accessToken, credentials);
    const payload = {
      Limit: 50,
      Offset: 0,
      FilterOptionsRequest: {
        CategoryFilter: [{ Id: categoryId.toString() }],
        ParameterFilterRequest: {
          CategoryFilter: { Id: categoryId.toString() },
          ParameterFilters: []
        }
      }
    };

    const response = await axios.post(SEARCH_URL, payload, { headers });
    
    if (response.status === 200) {
      const filterOptions = response.data.FilterOptions;
      if (filterOptions?.ParametricFilters) {
        return filterOptions.ParametricFilters;
      }
    }
    return [];
  } catch (error) {
    console.error("Error getting filters:", error);
    return [];
  }
};

// Fetch categories from backend
const getCategories = async (searchValue, numCategories = 5) => {
  try {
    const response = await axios.get(`${API_URL}/digikey/get-categories`, {
      params: {
        user_input: searchValue,
        num_categories: numCategories,
      },
    });
    
    if (response.data) {
      return response.data.split(',').map(id => parseInt(id.trim()));
    }
    return [];
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
};

// Fetch category info from backend
const getCategoryInfo = async (categoryId) => {
  try {
    const response = await axios.get(`${API_URL}/digikey/get-category-info`, {
      params: {
        category_id: categoryId,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error(`Error fetching category info for ${categoryId}:`, error);
    throw error;
  }
};

// Fetch sample products for a category
const getCategoryProducts = async (categoryId) => {
  try {
    // Get access token
    const authData = await getAccessTokenAndHeaders();
    if (!authData) {
      throw new Error("Failed to get Digi-Key access token");
    }
    const { token: accessToken, credentials } = authData;
    
    // Get sample products from the category
    const headers = getHeaders(accessToken, credentials);
    const payload = {
      Limit: 1,
      Offset: 0,
      FilterOptionsRequest: {
        CategoryFilter: [{ Id: categoryId.toString() }],
      }
    };
    
    const response = await axios.post(SEARCH_URL, payload, { headers });
    if (response.status === 200 && response.data.Products.length > 0) {
      const product = mapDigiKeyProduct(response.data.Products[0]);
      return product;
    }
    return null;
  } catch (error) {
    console.error(`Error fetching products for category ${categoryId}:`, error);
    return null;
  }
};

// Search for products in a category
const searchProducts = async (categoryId, selectedFilters = {}, limit = 50) => {
  try {
    // Get access token
    const authData = await getAccessTokenAndHeaders();
    if (!authData) {
      throw new Error("Failed to get Digi-Key access token");
    }
    const { token: accessToken, credentials } = authData;
    
    // Prepare headers
    const headers = getHeaders(accessToken, credentials);
    
    // Make multiple API calls if needed
    let allProducts = [];
    const maxLimit = 50;
    const numRequests = Math.ceil(limit / maxLimit);
    
    // Prepare requests
    const requests = Array.from({ length: numRequests }, (_, index) => {
      const currentLimit = index === numRequests - 1 ? limit % maxLimit || maxLimit : maxLimit;
      const offset = index * maxLimit;
      
      // Ensure all parameter IDs and values are properly formatted
      const parameterFilters = [];
      
      // Process each filter, ensuring proper type conversion
      Object.entries(selectedFilters).forEach(([parameterId, values]) => {
        // Skip if values is not an array or is empty
        if (!Array.isArray(values) || values.length === 0) return;
        
        // Convert parameterId to integer (DigiKey API expects integer IDs)
        const paramId = parseInt(parameterId, 10);
        if (isNaN(paramId)) return;
        
        // Convert all values to strings and format them as required by DigiKey API
        const filterValues = values
          .filter(v => v !== null && v !== undefined)
          .map(v => ({ Id: String(v) }));
        
        if (filterValues.length > 0) {
          parameterFilters.push({
            ParameterId: paramId,
            FilterValues: filterValues
          });
        }
      });
      
      const searchPayload = {
        Limit: currentLimit,
        Offset: offset,
        FilterOptionsRequest: {
          CategoryFilter: [{ Id: categoryId.toString() }],
          ParameterFilterRequest: {
            CategoryFilter: { Id: categoryId.toString() },
            ParameterFilters: parameterFilters
          }
        }
      };

      return axios.post(SEARCH_URL, searchPayload, { headers });
    });

    // Execute all requests
    const responses = await Promise.all(requests);
    
    // Process responses with error handling
    responses.forEach(response => {
      if (response.data && response.data.Products) {
        try {
          const products = response.data.Products.map(mapDigiKeyProduct);
          allProducts = [...allProducts, ...products];
        } catch (error) {
          console.error("Error processing product response:", error);
        }
      }
    });

    return allProducts;
  } catch (error) {
    console.error("Error searching for products:", error);
    throw error;
  }
};

// Get filtered products based on specifications
const getFilteredProducts = async (params) => {
  try {
    // Use the new v3 endpoint that handles everything with a single prompt
    const response = await axios.get(`${API_URL}/v3/digikey/get-filters`, {
      params: {
        user_input: params.user_input,
        category_id: params.category_id,
        category_name: params.category_name
      }
    });
    
    return response.data;
  } catch (error) {
    console.error("Error fetching filtered products:", error);
    throw error;
  }
};

// Search for products with a specific payload
const searchProductsWithPayload = async (payload, headers) => {
  try {
    const response = await axios.post(SEARCH_URL, payload, { headers });
    
    if (response.status === 200 && response.data.Products && response.data.Products.length > 0) {
      return response.data.Products.map(mapDigiKeyProduct);
    }
    return [];
  } catch (error) {
    console.error("Error searching with payload:", error);
    return [];
  }
};

export {
  getCategories,
  getCategoryInfo,
  getCategoryProducts,
  getAccessTokenAndHeaders,
  getFilterOptions,
  getHeaders,
  searchProducts,
  mapDigiKeyProduct,
  getFilteredProducts,
  searchProductsWithPayload
}; 